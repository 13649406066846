export async function aes256Encrypt(text) {
  let key = "6b1A3QjEPdhj5YNX8wPhcsiMGKrTEHFD";
  let iv = "eKxCwk0b52GJJjeb";
  // 浏览器环境
  const encoder = new TextEncoder();
  const encodedKey = encoder.encode(key);
  const encodedIv = encoder.encode(iv);
  const encodedText = encoder.encode(text);

  const cryptoKey = await crypto.subtle.importKey(
    "raw",
    encodedKey,
    { name: "AES-CBC" },
    false,
    ["encrypt"]
  );
  const encrypted = await crypto.subtle.encrypt(
    { name: "AES-CBC", iv: encodedIv },
    cryptoKey,
    encodedText
  );

  return btoa(String.fromCharCode(...new Uint8Array(encrypted)));
}
