<template>
  <div class="content hastip" v-if="type != 'pc'">
    <div class="container"></div>
    <div class="logo">
      <img :src="logo" alt="" class="logo" />
    </div>
    <div class="btn-container">
      <div class="label">
        <div class="title" id="title">{{ name }}</div>
        <div class="subtitle">请输入下载码/已注册手机号码进行下载</div>
      </div>
      <div class="input phone">
        <input v-model="value" placeholder="请输入下载码/已注册手机号码" />
      </div>
      <div v-if="value.length === 11" class="input">
        <input v-model="code" placeholder="请输入验证码" />
        <img :src="imgurl" alt="" class="auth-code" @click="imageClick">
      </div>

      <div class="btn android" @click="click">点击下载</div>
    </div>

    <footer>Copyright &copy; 2025 {{ name }}</footer>
    <div class="zoom" v-if="isWechat">
      <div class="open-browser">
        <p>请点击右上角···<br>选择“在浏览器中打开”</p>
      </div>
      <!-- <img src="../assets/images/app/go-safari.png" alt="" /> -->
    </div>
  </div>
</template>

<script>
import { typeFn } from '../utils'
import axios from "axios";
import { aes256Encrypt } from '../../../aes.js'
import { MD5 } from "crypto-js";

function isWechat() {
  var ua = navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == "micromessenger") {
    return true;
  } else {
    return false;
  }
}
export default {
  data() {
    return {
      baseUrl: config.baseUrl,
      value: "",
      isWechat: isWechat(),
      type: typeFn,
      code: '',
      t: Date.now()
    };
  },
  computed: {
    name() {
      return this.$store.state.appName
    },
    logo() {
      return this.$store.state.logo
    },
    imgurl() {
      if (this.value && this.value.length !== 11) return
      return `${this.baseUrl}/api/user/phoneCaptcha?phone=${MD5(this.value)}&t=${window.btoa(String(this.t))}`
    }
  },
  methods: {
    imageClick() {
      this.t = Date.now()
    },
    async click() {
      axios.get(this.baseUrl + "/api/system/ios?url=" + this.baseUrl);
      window.scrollTo(0, 0)
      if (!this.value) {
        this.$notify({
          type: "danger",
          message: "请输入下载码/已注册手机号码",
        });
        return;
      }
      if (!this.code && this.value.length === 11) {
        this.$notify({
          type: "danger",
          message: "请输入验证码",
        });
        return;
      }
      this.$toast.loading({
        message: "验证中...",
      });
      try {
        const res = await this.$axios.post(
          config.baseUrl + "/api/user/checkAffCode/v1",
          {
            affCode: await aes256Encrypt(this.value),
            code: this.value.length === 11 ? this.code : ''
          }
        );
        if (!res.data.data) {
          if (res.data.data !== false) {
            this.$notify({
              type: "danger",
              message: res.data.msg,
            });
          } else {
            this.$notify({
              type: "danger",
              message: "验证失败,请检查输入是否正确",
            });
          }
          this.imageClick()
        } else {
          this.$notify({ type: "success", message: "验证通过" });
          this.$store.commit('setAuthCode', { data: this.value })
          setTimeout(() => {
            this.$router.push({
              path: "/download"
            })
          }, 500);
        }
        this.$toast.clear();
        setTimeout(() => {
          window.scrollTo(0, 0)
        }, 100);
      } catch (err) {
        this.$notify({
          type: "danger",
          message: '网络连接失败,请检查网络或联系客服人员...',
        });
      }
    },
  },
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.input {
  width: 3rem;
  height: 0.45rem;
  font-size: 0.14rem;
  border-radius: 0.08rem;
  overflow: hidden;
  display: flex;
  background: #ffff;
  margin-bottom: 0.15rem;
  padding: 0 0.15rem;

  align-items: center;
  justify-content: space-between;

  input {
    width: 1.5rem;
    height: 100%;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.phone {
  input {
    width: 100%;
    height: 100%;
    border-right: none;
  }
}

.auth-code {
  height: 0.4rem;
  width: 1rem;
  margin-left: 0.05rem;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  font-family: -apple-system, BlinkMacSystemFont, PingFang SC, Helvetica Neue,
    STHeiti, Microsoft Yahei, Tahoma, Simsun, sans-serif;
}

.content {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  padding: 0.6rem 0;
}

.container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  background-size: cover;
}

.logo {
  width: 1rem;
  height: 1rem;
  margin: 0 auto 0.15rem;
  -moz-background-size: cover;
  background-size: cover;
  border-radius: 0.15rem;
}

.btn-container,
.logo {
  position: relative;
  z-index: 2;
}

.btn-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.label {
  font-size: 0.32rem;
  color: #323232;
  margin-bottom: 0.3rem;
}

.title {
  font-size: 0.24rem;
  font-weight: 500;
  margin-bottom: 0.1rem;
  /* margin-top: 20px; */
}

.subtitle,
.title {
  text-align: center;
  color: #fff;
}

.subtitle {
  /* margin-top: 10px; */
  font-size: 0.14rem;
}

.btn {
  margin: 0.3rem auto 0.2rem;
  font-size: 0.18rem;
  border-radius: 0.08rem;
  background: linear-gradient(90deg,
      rgba(16, 111, 255, 1),
      rgba(63, 207, 255, 1));
  box-shadow: 0px 1px 2px 0px rgba(255, 255, 255, 0.18);
}

.btn,
.btn a {
  width: 80vw;
  height: 0.5rem;
  line-height: 0.5rem;
  color: #fff;
}

.btn a {
  display: block;
  text-decoration: none;
}

footer {
  position: absolute;
  width: 100%;
  bottom: 0.3rem;
  color: #999;
  font-size: 0.1rem;
  text-align: center;
}

.zoom {
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.open-browser {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTAycHgiIGhlaWdodD0iMTMzcHgiIHZpZXdCb3g9IjAgMCAxMDIgMTMzIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1Mi41ICg2NzQ2OSkgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+6Lev5b6EIDI8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iSU9TLea1t+Wkli3lrprnqL8tQ29weSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTU3Ni4wMDAwMDAsIC0xNzYuMDAwMDAwKSIgZmlsbD0iI0ZGRkZGRiIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPHBhdGggaWQ9Iui3r+W+hC0yIiBkPSJNNjY2LjQ0Mzk2LDE4Ny41Mjk4MjQgTDY0OC42NTg4MDgsMTk4LjA2ODc5OCBDNjQ3LjIzMzQxOCwxOTguOTEzNDQ0IDY0NS4zOTMxODksMTk4LjQ0MjY1NyA2NDQuNTQ4NTQzLDE5Ny4wMTcyNjYgQzY0My43MDM4OTcsMTk1LjU5MTg3NSA2NDQuMTc0Njg0LDE5My43NTE2NDcgNjQ1LjYwMDA3NSwxOTIuOTA3MDAxIEw2NzMuMjM3ODIzLDE3Ni41Mjk2NTggQzY3NS4yNTAzOTksMTc1LjMzNzA2MyA2NzcuNzkyOTIxLDE3Ni44MDQ1NDQgNjc3Ljc2NzAwNiwxNzkuMTQzNzkgTDY3Ny40MTExMjcsMjExLjI2NzU0OCBDNjc3LjM5Mjc3MywyMTIuOTI0MzAxIDY3Ni4wMzQ4MywyMTQuMjUyNDg1IDY3NC4zNzgwNzgsMjE0LjIzNDEzMSBDNjcyLjcyMTMyNSwyMTQuMjE1Nzc3IDY3MS4zOTMxNDEsMjEyLjg1NzgzNSA2NzEuNDExNDk1LDIxMS4yMDEwODIgTDY3MS42NDA1MDksMTkwLjUyODkzOSBMNjYzLjM0ODU3MywyMDQuODk1MDEzIEM2MzIuNTE2NTkyLDI1Ni42OTk3MyA2MDUuMDMwODg2LDI5MS4yMDAwNjUgNTgwLjczNjU4MiwzMDguNDQ2Mjc5IEM1NzkuMzg1NTQsMzA5LjQwNTM2NyA1NzcuNTEyODA5LDMwOS4wODc2MjUgNTc2LjU1MzcyMSwzMDcuNzM2NTgyIEM1NzUuNTk0NjMzLDMwNi4zODU1NCA1NzUuOTEyMzc1LDMwNC41MTI4MDkgNTc3LjI2MzQxOCwzMDMuNTUzNzIxIEM2MDAuNzI0MDMyLDI4Ni44OTkzMzIgNjI3Ljc1MjA2MiwyNTIuOTczNDc2IDY1OC4xNzIzMywyMDEuODYxMDU2IEw2NjYuNDQzOTYsMTg3LjUyOTgyNCBaIj48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=) no-repeat;
  background-position: 3rem 0.2rem;
  background-size: 10%;

}

.open-browser p {
  position: absolute;
  right: 0.2rem;
  top: 0.7rem;
  font-size: 0.14rem;
  line-height: 0.26rem;
  text-align: center;
  font-weight: 700;
}
</style>
